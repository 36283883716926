import {
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Switch
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Delete, Edit } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { AstroImage, ColumnHeader } from "../../types";
import { deleteImageRec, updateImage } from "../../utils/images-api";
import EditImageDialog from "./edit-image-dialog";

const columns: ColumnHeader<AstroImage>[] = [
  // { key: "id" },
  { key: "title" },
  { key: "source" },
  // { key: "sourceUrl" },
  // { key: "thumbnail" },
  // { key: "thumbnailUrl" },
  { key: "description" },
  { key: "thumbnail" },
  { key: "dateTaken" },
  { key: "width" },
  { key: "height" },
  { key: "released" },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    progress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    thumbnail: {
      height: 90,
    },
  })
);

function TableColumnHeaders(props: { tableColumns: ColumnHeader<any>[] }) {
  const { tableColumns } = props;

  return (
    <React.Fragment>
      {tableColumns.map((column) => {
        const align = column.align || "center";
        return (
          <TableCell
            key={column.key as string}
            // sortDirection={sort}
            align={align}
          >
            {/* <TableSortLabel></TableSortLabel> */}
            {column.label || column.key}
          </TableCell>
        );
      })}
    </React.Fragment>
  );
}

function ListImageReleased(props: { image: AstroImage; }) {
  const [image, setImage] = useState<AstroImage>(props.image);
  const [released, setReleased] = useState<boolean>(props.image.released === true ? true : false);
  const [updating, setUpdating] = useState<boolean>(false);


  useEffect(() => {
    // Fixes odd behavior with Switch not drawing properly on initial load. Previously would
    // keep the state of whatever line below in table regardless of "released" value (╯°□°）╯︵ ┻━┻
    setReleased(props.image.released === true ? true : false);
  }, [props.image]);

  const handleReleaseChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    imageRecord: AstroImage
  ) => {
    setReleased(event.target.checked);
    setUpdating(true);
    imageRecord.released = event.target.checked;
    
    updateImage({ image: imageRecord }).then(() => {
      setImage(imageRecord);
      setUpdating(false);
    });
  };

  return (
    <Switch
      checked={released}
      disabled={updating}
      onChange={(event) => handleReleaseChange(event, image)}
    />
  );
}

export function ListImages(props: { imagesList: AstroImage[]; fetchImages: () => void }) {
  const imagesList = props.imagesList;
  const classes = useStyles();
  const [deleting, setDeleting] = React.useState(false);
  const [editDialog, setEditDialog] = React.useState<JSX.Element|undefined>();

  const deleteImage = (imageRecord: AstroImage) => {
    // TODO: CONFIRMATION!!
    setDeleting(true);
    deleteImageRec({ image: imageRecord }).then(() => {
      props.fetchImages();
      setDeleting(false);
    });
  };

  const handleEditSubmit = () => {
    props.fetchImages();
  }

  const handleEdit = (imageRecord: AstroImage) => {
    const dialog = (
      <EditImageDialog
        open={true}
        image={imageRecord}
        handleClose={handleEditClose}
        handleSubmit={handleEditSubmit}
      />
    );
    setEditDialog(dialog);
  }

  const handleEditClose = () => {
    setEditDialog(undefined);
  }

  return (
    <div>
      {editDialog}
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableColumnHeaders tableColumns={columns} />
              </TableRow>
            </TableHead>
            <TableBody>
              {imagesList
                ? imagesList.map((row: AstroImage) => {
                    return (
                      <TableRow className={classes.row}>
                        {columns.map((column) => {
                          const align = column.align || "center";
                          const value =
                            row === null || row[column.key] == null
                              ? ""
                              : (row[column.key] as string);
                          let cell;
                          if (column.key === "thumbnail") {
                            cell = value ? (
                              <Link href={row.sourceUrl ?? ""} target="_blank">
                                <img src={row.thumbnailUrl ?? ""} alt={row.description ?? ""} className={classes.thumbnail} />
                              </Link>
                            ) : (
                              ""
                            );
                          } else if (column.key === "released") {
                            cell = <ListImageReleased image={row} />
                          } else {
                            cell = value;
                          }
                          return (
                            <TableCell key={column.key} align={align}>
                              {cell}
                            </TableCell>
                          );
                        })}
                        <TableCell>
                          <IconButton
                            aria-label="delete"
                            disabled={deleting}
                            onClick={() => deleteImage(row)}
                          >
                            <Delete />
                            {deleting && (
                              <CircularProgress size={24} className={classes.progress} />
                            )}
                          </IconButton>
                          <IconButton aria-label="edit" onClick={() => handleEdit(row)}>
                            <Edit />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
