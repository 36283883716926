import { Typography } from '@material-ui/core';
import React from 'react';
import AstroHeader from '../components/astro-header';
import { MainSectionProps } from '../types';

export const About = React.forwardRef<HTMLDivElement, MainSectionProps>((props: MainSectionProps, ref) => {
  return (
    <div style={props.style} ref={ref}>
      <AstroHeader title="About" />
      <Typography>Coming soon...</Typography>
    </div>
  );
});