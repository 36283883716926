import { makeStyles, Box, Theme, createStyles, CircularProgress } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import ImageGallery, { ReactImageGalleryItem as ImageItem } from "react-image-gallery";
import AstroHeader from "../components/astro-header";
import { AppContext } from "../contexts/app-context";
import { AstroImage, MainSectionProps } from "../types";
import { getImages } from "../utils/images-api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleEm: {
      marginBottom: 15,
      borderBottom: "2px solid",
      borderImage: "linear-gradient(90deg, #007bb2, #f50057)",
      borderImageSlice: 1,
      display: "inline-block",
    },
    galleryLoading: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: 800,
    },
    galleryHidden: {
      display: "none",
    },
  })
);

const setImageItemsFromApi = (params: { images: AstroImage[] }) => {
  const imageItems: ImageItem[] = [];
  params.images.forEach((image) => {
    if (image.sourceUrl) {
      const imageItem: ImageItem = {
        original: image.sourceUrl,
        thumbnail: image.thumbnailUrl ? image.thumbnailUrl : undefined,
        originalTitle: image.title ? image.title : undefined,
        thumbnailTitle: image.title ? image.title : undefined,
        description:
          (image.description ? image.description : undefined) +
          (image.dateTaken ? " - " + image.dateTaken : ""),
        // originalHeight: image.height ?? undefined,
        // originalWidth: image.width ?? undefined,
      };
      imageItems.push(imageItem);
    }
  });
  return imageItems;
};

type scrollPosition = {
  scrollX: number;
  scrollY: number;
}

export const Gallery = React.forwardRef<HTMLDivElement, MainSectionProps>(
  (props: MainSectionProps, ref) => {
    const { state, dispatch } = useContext(AppContext);
    const [images, setImages] = useState<ImageItem[]>();
    const [imagesLoading, setImagesLoading] = useState(true);
    const [scrollPos, setScrollPos] = useState<scrollPosition>();
    const [fullscreen, setFullscreen] = useState(false);
    const classes = useStyles();

    // Fetch image records from API and save to AppContext
    const fetchImages = useCallback(async () => {
      if (!images) {
        getImages({ includeUnReleased: false }).then((images) => {
          dispatch({ type: "SET_IMAGES", payload: images });
        });
      }
    }, [images, dispatch]);

    useEffect(() => {
      fetchImages();
    }, [fetchImages]);

    // Update local "images" state when AppContext "state.images" changes,
    //   which will also trigger gallery to render
    useEffect(() => {
      const imageItems = setImageItemsFromApi({ images: state.images });
      setImages(imageItems);
    }, [state.images]);

    // Show placeholder while gallery is initially loaded (triggered by first onImageLoad event)
    const handleImageLoad = useCallback(() => {
      if (imagesLoading === true) {
        setImagesLoading(false);
      }
    }, [imagesLoading]);

    useEffect(() => {
      if (fullscreen === false && scrollPos) {
        window.scrollTo(scrollPos.scrollX, scrollPos.scrollY);
      }
    }, [fullscreen, scrollPos]);

    const handleScreenChange = (fullscreen: boolean) => {
      if (fullscreen) {
        setScrollPos({ scrollX: window.pageXOffset, scrollY: window.pageYOffset });
        setFullscreen(true);
      } else if (scrollPos) {
        setFullscreen(false);
      }
    };

    return (
      <div style={props.style} ref={ref}>
        <AstroHeader title="Gallery" />
        <Box>
          {images ? (
            <Box className={imagesLoading ? classes.galleryLoading : undefined}>
              {imagesLoading ? <CircularProgress /> : ""}
              <ImageGallery
                items={images}
                lazyLoad={false}
                onImageLoad={handleImageLoad}
                onScreenChange={handleScreenChange}
                showThumbnails={!fullscreen}
                useBrowserFullscreen={false}
                additionalClass={imagesLoading ? classes.galleryHidden : undefined}
              />
            </Box>
          ) : (
            ""
          )}
        </Box>
      </div>
    );
  }
);
