import React from "react";
import { Auth } from "aws-amplify";
import { AuthError } from "@aws-amplify/auth/lib/Errors";
import { Box, Button, CircularProgress, Container, createStyles, FormControl, FormHelperText, Grid, makeStyles, Paper, TextField, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    buttonWrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    formPaper: {
      padding: 25,
    }
  })
);

export default function AstroSignIn(props: { setLoggedIn: (value: boolean) => void }) {
  const classes = useStyles();
  const [formData, setFormData] = React.useState({ username: '', password: '', code: '' });
  const [loginLoading, setLoginLoading] = React.useState(false);
  const [formError, setFormError] = React.useState(false);
  const [helperText, setHelperText] = React.useState('');

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSignIn = async () => {
    setLoginLoading(true);
    await Auth.signIn(formData.username, formData.password).then(
      () => {
        props.setLoggedIn(true);
        console.log("Logged in!");
        setLoginLoading(false);
      },
      (error: AuthError) => {
        setFormError(true);
        setHelperText(error.message);
        props.setLoggedIn(false);
        console.error("Login failed", error);
        setLoginLoading(false);
      }
    );
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h1">Sign In</Typography>
      <Box mt={2} display="flex" flexDirection="column">
        <Paper className={classes.formPaper} elevation={0}>
          <form noValidate>
            <Grid container justify="center" alignItems="center" spacing={2} direction="column">
              <Grid item>
                <TextField
                  name="username"
                  id="username"
                  key="username"
                  label="username"
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  name="password"
                  id="password"
                  key="password"
                  label="password"
                  variant="outlined"
                  type="password"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <Box className={classes.buttonWrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSignIn}
                    disabled={loginLoading}
                  >
                    Login
                    {loginLoading && <CircularProgress size={24} className={classes.progress} />}
                  </Button>
                </Box>
              </Grid>
              <Grid item hidden={!formError} >
                <FormControl error={formError}>
                  <FormHelperText>{helperText}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Box>
    </Container>
  );
}
