import { AppBar, createStyles, Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#6d1b7b",
      padding: "20px 0",
      marginTop: 40,
    },
    footerName: {
      color: theme.palette.secondary.light,
      fontWeight: 500,
      textTransform: "uppercase",
      letterSpacing: "0.3em",
      marginBottom: "0.6em",
      paddingBottom: "0.3em",
      borderBottom: "2px solid",
      borderImage: "linear-gradient(90deg, #6d1b7b 0%, #f50057 50%, #6d1b7b 100%)",
      borderImageSlice: 1,
    },
    vertSpan: {
      color: theme.palette.secondary.light,
    },
    footerHR: {
      margin: ".2em 0",
    },
    footerText: {
      lineHeight: "2em",
      whiteSpace: "nowrap",
    },
    footerLink: {
      color: "#fff",
    },
  })
);

export default function Footer() {
  const classes = useStyles();
  const year = new Date().getFullYear();

  return (
    <footer>
      <AppBar position="static" className={classes.root}>
        <Grid container direction="column" justify="center" alignItems="stretch">
          <Grid item container justify="center">
            <Grid item sm={4}>
              <Typography variant="body1" align="center" className={classes.footerName}>
                Eric Jenkins
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="body2" align="center">
              Copyright © {year}
              <span className={classes.vertSpan}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <Link href="/" className={classes.footerLink}>
                deep-sky-dad.com
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </AppBar>
    </footer>
  );
}
