import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Moment } from "moment";
import React, { ChangeEvent, useState } from "react";
import { AstroImage } from "../../types";
import { updateImage } from "../../utils/images-api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      "& > *": {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      overflowY: "initial",
    },
    progress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);
export default function EditImageDialog(props: {
  open: boolean;
  image: AstroImage;
  handleClose: () => void;
  handleSubmit: () => void;
}) {
  const [open, setOpen] = useState(props.open);
  const [image, setImage] = useState<AstroImage>(props.image);
  const [dateTaken, setDateTaken] = useState<Moment | any>(props.image.dateTaken);
  const [updating, setUpdating] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    // Call parent to pass up closed state (otherwise it won't know to re-create subsequent dialogs)
    props.handleClose();
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setImage((currentState) => ({ ...currentState, [name]: value }));
  };

  const handleSubmit = async () => {
    setUpdating(true);
    console.log("submitting : ", image);

    await updateImage({ image: image }).then(() => {
      setUpdating(false);
      handleClose();
      props.handleSubmit();
    });
    
  };

  const handleDateTakenChange = (value: Moment|any) => {
    setDateTaken(value);
    setImage((currentState) => ({ ...currentState, dateTaken: value.format("YYYY-MM-DD") }));
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="xl">
        <DialogTitle id="form-dialog-title">Edit {props.image.title}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TextField
            name="title"
            label="Title"
            variant="outlined"
            fullWidth
            value={image.title}
            onChange={handleChange}
          />
          <TextField
            name="description"
            label="Description"
            variant="outlined"
            fullWidth
            value={image.description}
            onChange={handleChange}
          />
          <TextField
            name="source"
            label="Source"
            variant="outlined"
            fullWidth
            value={image.source}
            onChange={handleChange}
          />
          <TextField
            name="sourceUrl"
            label="Source URL"
            variant="outlined"
            fullWidth
            value={image.sourceUrl}
            onChange={handleChange}
          />
          <TextField
            name="thumbnail"
            label="Thumbnail"
            variant="outlined"
            fullWidth
            value={image.thumbnail}
            onChange={handleChange}
          />
          <TextField
            name="thumbnailUrl"
            label="Thumbnail URL"
            variant="outlined"
            fullWidth
            value={image.thumbnailUrl}
            onChange={handleChange}
          />
          <KeyboardDatePicker
            label="Date Taken"
            name="dateTaken"
            disableToolbar
            variant="inline"
            format="YYYY-MM-DD"
            margin="normal"
            value={dateTaken}
            onChange={handleDateTakenChange}
          />
          <TextField
            name="width"
            label="Width"
            variant="outlined"
            type="number"
            value={image.width}
            onChange={handleChange}
          />
          <TextField
            name="height"
            label="Height"
            variant="outlined"
            type="number"
            value={image.height}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained" disabled={updating}>
            Update
            {updating && <CircularProgress size={24} className={classes.progress} />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
