import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { Gallery } from './gallery';
import { Equipment } from './equipment';
import { About } from './about';

const subSectionStyle = {
  marginBottom: 25,
};

export function Main() {
  const gallery = React.useRef<HTMLDivElement>(null);
  const equipment = React.useRef<HTMLDivElement>(null);
  const about = React.useRef<HTMLDivElement>(null);
  const location = useLocation().pathname;

  React.useEffect(() => {
    switch (location) {
      case "/gallery": {
        gallery.current?.scrollIntoView({ behavior: "smooth" });
        break;
      }
      case "/equipment": {
        equipment.current?.scrollIntoView({ behavior: "smooth" });
        break;
      }
      case "/about": {
        about.current?.scrollIntoView({ behavior: "smooth" });
        break;
      }
      default: {
        // do nothing
        break;
      }
    }
  });

  return (
    <Container>
      <Gallery ref={gallery} style={subSectionStyle} />
      <Equipment ref={equipment} style={subSectionStyle} />
      <About ref={about} style={subSectionStyle} />
    </Container>
  );
}