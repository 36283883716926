import { lightBlue, purple } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

export const appTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: purple,
    secondary: lightBlue,
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    h1: {
      fontSize: "3rem",
      fontWeight: 200,
      lineHeight: 1.43,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 300,
    },
  },
});
