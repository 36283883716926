import { Route, Switch } from 'react-router-dom';
import { Header } from '../components/header';
import { Box } from '@material-ui/core';
import { Main } from './main';
import { NotFound } from './404';
import Footer from '../components/footer';

export function Root() {
  return (
    <div>
      <Header />
      <Box>
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/gallery" component={Main} />
          <Route path="/equipment" component={Main} />
          <Route path="/about" component={Main} />
          {/* Unmatched path: */}
          <Route path="*" component={NotFound} />
        </Switch>
      </Box>
      <Footer />
    </div>
  );
}