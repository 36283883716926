import React from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Drawer,
  Toolbar,
  Box,
  List,
  ListItem,
  ListItemText,
  Container,
  IconButton,
  useMediaQuery,
  Typography,
} from "@material-ui/core";
import { Menu, CloseRounded } from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { AppContext } from "../contexts/app-context";
import HeaderBg from "../assets/images/header-bg3.jpg";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#2b2b2b",
    backgroundImage: `url(${HeaderBg})`,
    backgroundPosition: "center",
    height: 500,
    marginBottom: 15,
    boxShadow: "none",
    borderBottom: "10px solid",
    borderImage: "linear-gradient(90deg, #6d1b7b, #007bb2)",
    borderImageSlice: 1,
  },
  nav: {
    display: "flex",
    height: 500,
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 200,
    fontSize: "4em",
    margin: 0,
    borderBottom: "5px solid",
    borderImage: "linear-gradient(90deg, #007bb2, #f50057)",
    borderImageSlice: 1,
  },
  subTitle: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 400,
    fontSize: "1.5em",
    marginBlock: ".83em",
  },
  navList: {
    display: "flex",
    justifyContent: "space-between",
  },
  navLinks: {
    // TODO: Animated underline on hover instead
    textDecoration: "none",
    textTransform: "uppercase",
    color: "#fff",
  },
  mobileNavListLinks: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 400,
  },
});

type NavLinks = {
  title: string;
  path: string;
};

const links: NavLinks[] = [
  { title: "Home", path: "/" },
  { title: "Gallery", path: "/gallery" },
  { title: "Equipment", path: "/equipment" },
  { title: "About", path: "/about" },
];

export function Header() {
  const { state, dispatch } = React.useContext(AppContext);
  const theme = useTheme();
  const classes = useStyles();

  const isMobile = useMediaQuery(theme.breakpoints.up("md"));

  var nav;
  if (isMobile) {
    nav = (
      <List
        component="nav"
        aria-labelledby="main navigation"
        className={classes.navList}
      >
        {links.map(({ title, path }) => (
            <ListItem button component={Link} to={path} className={classes.navLinks}>
              <ListItemText primary={title} />
            </ListItem>
        ))}
      </List>
    );
  } else {
    nav = (
      <IconButton
        edge="start"
        aria-label="menu"
        onClick={() => {
          dispatch({ type: "TOGGLE_DRAWER" });
        }}
      >
        <Menu fontSize="large" />
        <Drawer
          open={state.drawerOpen}
          anchor="right"
          variant="temporary"
          onClose={() => dispatch({ type: "TOGGLE_DRAWER" })}
          onBackdropClick={() => dispatch({ type: "TOGGLE_DRAWER" })}
        >
          <List component="nav">
            {links.map(({ title, path }) => (
                <ListItem button component={Link} to={path} className={classes.navLinks}>
                  <ListItemText
                    primary={title}
                    classes={{ primary : classes.mobileNavListLinks }}
                  />
                </ListItem>
            ))}
            <ListItem>
              <CloseRounded />
            </ListItem>
          </List>
        </Drawer>
      </IconButton>
    );
  }

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar>
        <Container className={classes.nav}>
          <Box>
            <Typography className={classes.title}>Deep Sky Dad</Typography>
            <Typography className={classes.subTitle}>
              Backyard Astrophotographer from N.C.
            </Typography>
          </Box>
          {nav}
        </Container>
      </Toolbar>
    </AppBar>
  );
}
