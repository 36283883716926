import React, { Dispatch } from 'react';
import { AstroImage } from "../types";

type AppState = {
  drawerOpen: boolean,
  images: AstroImage[],
}

const initialState: AppState = {
  drawerOpen: false,
  images: [],
}

type AppActions =
  | { type: 'TOGGLE_DRAWER' }
  | { type: 'DRAWER_CLOSE' }
  | { type: 'SET_IMAGES'; payload: AstroImage[] };

const reducer = (state: AppState, action: AppActions) => {
  switch (action.type) {
    case 'TOGGLE_DRAWER': {
      return { ...state, drawerOpen: !state.drawerOpen };
    }
    case 'DRAWER_CLOSE': {
      return { ...state, drawerOpen: false };
    }
    case 'SET_IMAGES': {
      const images = action.payload;
      return { ...state, images }
    }
    default: {
      return { ...state };
    }
  }
};

export const AppContext = React.createContext(
  {} as {
    state: AppState;
    dispatch: Dispatch<AppActions>
  }
)

export function AppProvider(props: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AppContext.Provider>
  )
}