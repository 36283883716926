import { Box, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  titleEm: {
    marginBottom: 15,
    borderBottom: "2px solid",
    borderImage: "linear-gradient(90deg, #007bb2, #f50057)",
    borderImageSlice: 1,
    display: "inline-block",
  },
});

export default function AstroHeader(props: { title: string }) {
  const title = props.title;
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="h1">
        <Box className={classes.titleEm}>{title}</Box>
      </Typography>
    </Box>
  );
}
