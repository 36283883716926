import React, { useContext, useEffect, useState, ChangeEvent, useCallback } from "react";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { AstroImage } from "../types";
import { ListImages } from "../components/admin/list-images";
import { DropzoneArea } from "material-ui-dropzone";
import { AppContext } from "../contexts/app-context";
import { getImages, addImage, getThumbWidthHeight, batchRefactorUrls } from "../utils/images-api";
import AstroSignIn from "../components/admin/sign-in";
import { Auth } from "aws-amplify";
import MomentUtils from "@date-io/moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Moment } from "moment";

export const ThumbnailMaxSize = 250;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbPaper: {
      width: ThumbnailMaxSize + 10,
      height: ThumbnailMaxSize + 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: theme.spacing(2),
    },
    dropzone: {
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
    previewImg: {
      maxWidth: "100%",
      maxHeight: "100%",
    },
    flexRow: {
      "&>:nth-child(1)": {
        paddingRight: theme.spacing(1),
      },
      "&>:nth-child(2)": {
        paddingLeft: theme.spacing(1),
      },
    },
    datePicker: {
      margin: 0,
    },
    progress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    buttonWrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    paperForm: {
      display: "flex",
      padding: 25,
    },
    divider: {
      margin: "15px 0",
    },
    signOut: {
      width: 160,
      position: "absolute",
      top: 0,
      right: 0,
      "--amplify-primary-color": theme.palette.primary.main,
      "--amplify-primary-shade": theme.palette.primary.light,
      "--amplify-primary-tint": theme.palette.primary.dark,
    },
  })
);

const initialDetails: AstroImage = {
  id: "",
  source: "",
  sourceUrl: null,
  thumbnail: null,
  thumbnailUrl: null,
  title: null,
  description: null,
  height: null,
  width: null,
  dateTaken: null,
  released: false,
};

function AdminWrapper() {
  const { dispatch } = useContext(AppContext);
  const [loggedIn, setLoggedIn] = useState(false);
  const [adminInterface, setAdminInterface] = useState<JSX.Element>(<Box />);

  useEffect(() => {
    const isLoggedIn = async () => {
      await Auth.currentAuthenticatedUser()
        .then(() => {
          setLoggedIn(true);
          setAdminInterface(
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Admin setLoggedIn={setLoggedIn} />
            </MuiPickersUtilsProvider>
          );
        })
        .catch((error) => {
          setAdminInterface(<AstroSignIn setLoggedIn={setLoggedIn} />);
          setLoggedIn(false);
        });
    };

    isLoggedIn();
  }, [loggedIn, dispatch]);

  return <React.Fragment>{adminInterface}</React.Fragment>;
}

function Admin(props: { setLoggedIn: (value: boolean) => void }) {
  const { state, dispatch } = useContext(AppContext);
  const [details, setDetails] = useState<AstroImage>(initialDetails);
  const [dateTaken, setDateTaken] = useState<Moment | any>(initialDetails.dateTaken);
  const [files, setFiles] = useState<File[]>([]);
  const [thumbnail, setThumbnail] = useState<File>();
  const [thumbnailUri, setThumbnailUri] = useState<any>("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [fetchImagesLoading, setImagesLoading] = useState(false);
  const classes = useStyles();

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setDetails((currentState) => ({ ...currentState, [name]: value }));
  };

  const handleDateTakenChange = (value: Moment | any) => {
    setDateTaken(value);
    setDetails((currentState) => ({ ...currentState, dateTaken: value.format("YYYY-MM-DD") }));
    console.log("updated details", details);
  };

  // Trigger thumbnail generation whenever file is added
  useEffect(() => {
    if (files[0]) {
      getThumbWidthHeight({ file: files[0], maxSize: ThumbnailMaxSize }).then((imageReader) => {
        // Update width, height and description from uploaded image read
        setDetails((details) => {
          return {
            ...details,
            source: imageReader.source,
            sourceUrl: `/images/gallery/${imageReader.source}`,
            thumbnail: imageReader.thumbnail.name,
            thumbnailUrl: `/images/gallery/thumbnails/${imageReader.thumbnail.name}`,
            width: imageReader.width,
            height: imageReader.height,
            description: imageReader.title,
          };
        });

        // Get thumbnail File and convert to base64 for display image src URI
        const thumb = imageReader.thumbnail;
        const reader = new FileReader();
        reader.readAsDataURL(thumb);
        reader.onloadend = (e) => {
          setThumbnailUri(e.target?.result);
          setThumbnail(thumb);
        };
      });
    }
  }, [files]);

  const handleSubmit = async () => {
    setSubmitLoading(true);
    if (!files[0]) {
      // TODO: Make global Snackbar context and send as Snackbar message
      alert("No uploaded file");
      return;
    }

    if (!thumbnail) {
      alert("No thumbnail file was generated");
      return;
    }

    try {
      addImage({ details: details }).then(() => {
        setSubmitLoading(false);
        window.location.reload();
      });
    } catch (error) {
      // TODO: error was already logged by addImage() so display Snackbar message
      setSubmitLoading(false);
    }
  };

  const fetchImages = useCallback(async () => {
    setImagesLoading(true);
    getImages({ includeUnReleased: true }).then((images) => {
      dispatch({ type: "SET_IMAGES", payload: images });
      console.log(images);
      setImagesLoading(false);
    });
  }, [dispatch]);

  // Fetch images on page load
  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  const batchRenameUrls = async () => {
    batchRefactorUrls();
  };

  return (
    <Container maxWidth={false}>
      <Box>
        <AmplifySignOut
          className={classes.signOut}
          handleAuthStateChange={() => props.setLoggedIn(false)}
        />
      </Box>
      <Typography variant="h1">Admin</Typography>
      <Container>
        <Paper className={classes.paperForm}>
          <form noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={3}>
                  <DropzoneArea
                    acceptedFiles={["image/*"]}
                    filesLimit={1}
                    dropzoneText={"Drag and drop an image here or click"}
                    showPreviewsInDropzone={false}
                    dropzoneClass={classes.dropzone}
                    onChange={setFiles}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Paper elevation={3} className={classes.thumbPaper}>
                    {thumbnailUri && <img src={thumbnailUri} alt="thumbnail" />}
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  Source Image:
                  {details.sourceUrl && (
                    <Link href={details.sourceUrl} target="_blank">
                      <img src={details.sourceUrl} alt="" className={classes.previewImg} />
                    </Link>
                  )}
                </Grid>
                <Grid item xs={3}>
                  Thumbnail:
                  {details.thumbnailUrl && (
                    <Link href={details.thumbnailUrl} target="_blank">
                      <img src={details.thumbnailUrl} alt="" className={classes.previewImg} />
                    </Link>
                  )}
                </Grid>
              </Grid>
              <Grid item container xs={12} className={classes.flexRow}>
                <Grid item xs={6}>
                  <TextField
                    name="source"
                    label="Source"
                    variant="outlined"
                    fullWidth
                    value={details.source || ""}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="thumbnail"
                    label="Thumbnail"
                    variant="outlined"
                    fullWidth
                    value={details.thumbnail || ""}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="sourceUrl"
                  label="Source URL"
                  variant="outlined"
                  fullWidth
                  value={details.sourceUrl || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="thumbnailUrl"
                  label="Thumbnail URL"
                  variant="outlined"
                  fullWidth
                  value={details.thumbnailUrl || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="title"
                  label="Title"
                  variant="outlined"
                  fullWidth
                  value={details.title || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="description"
                  label="Description"
                  variant="outlined"
                  fullWidth
                  value={details.description || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <KeyboardDatePicker
                  label="Date Taken"
                  name="dateTaken"
                  inputVariant="outlined"
                  className={classes.datePicker}
                  disableToolbar
                  variant="inline"
                  format="YYYY-MM-DD"
                  margin="normal"
                  value={dateTaken || null}
                  onChange={handleDateTakenChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  name="width"
                  label="Width"
                  variant="outlined"
                  type="number"
                  value={details.width || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  name="height"
                  label="Height"
                  variant="outlined"
                  type="number"
                  value={details.height || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Box width="100%" />
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={submitLoading}
                >
                  Submit
                  {submitLoading && <CircularProgress size={24} className={classes.progress} />}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
      <Divider className={classes.divider} />
      <Box>
        <ListImages imagesList={state.images} fetchImages={fetchImages} />
        <Box mt={1}>
          <Button
            variant="contained"
            color="secondary"
            onClick={fetchImages}
            disabled={fetchImagesLoading}
          >
            Load images
            {fetchImagesLoading && <CircularProgress size={24} className={classes.progress} />}
          </Button>
        </Box>
        <Box mt={1}>
          <Button variant="contained" color="primary" onClick={batchRenameUrls}>
            Refactor JPEG URLs
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default AdminWrapper;
