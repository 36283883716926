import API, { graphqlOperation, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import Storage from "@aws-amplify/storage";
import { listImages } from "../graphql/queries";
import { createImage, deleteImage, updateImage as updateImageMut } from "../graphql/mutations";
import { ListImagesQuery } from "../API";
import { AstroImage, ImageReaderType } from "../types";
import { base64ToBlob } from 'base64-blob';

export async function getImages(params: { includeUnReleased?: boolean }): Promise<AstroImage[]> {
  try {
    let filter = null;
    if (params.includeUnReleased !== true) {
      filter = {
        released: { eq: true }
      };
    }

    const response = (await API.graphql({
      query: listImages,
      variables: { filter: filter },
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM, // IMPORTANT: This is to allow guest/public access
    })) as { data: ListImagesQuery };

    const imagesList = response.data.listImages?.items
      ?.map((image) => {
        if (image) {
          return {
            id: image.id,
            source: image.source,
            sourceUrl: image.sourceUrl,
            thumbnail: image.thumbnail,
            thumbnailUrl: image.thumbnailUrl,
            title: image.title,
            description: image.description,
            dateTaken: image.dateTaken,
            height: image.height,
            width: image.width,
            released: image.released,
          } as AstroImage;
        } else {
          return {} as AstroImage;
        }
      })
      .sort((a, b) => {
        // Sort by dateTaken in descending order
        const aTime = a.dateTaken ? new Date(a.dateTaken).getTime() : 0;
        const bTime = b.dateTaken ? new Date(b.dateTaken).getTime() : 0;
        return bTime - aTime;
      });
    console.log("Image records loaded");
    return new Promise<AstroImage[]>((resolve) => {
      resolve(imagesList ? imagesList : []);
    });
  } catch (error) {
    console.error("Error getting images from API", error);
    return [];
  }
}

export async function addImage(params: { details: AstroImage }) {
  // Add Image record via AWS GraphQL API
  try {
    delete params.details.id;
    await API.graphql(graphqlOperation(createImage, { input: params.details }));
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updateImage(params: {
  image: AstroImage
}) {
  try {
    await API.graphql(graphqlOperation(updateImageMut, { input: params.image }));
    return new Promise<boolean>((resolve) => {resolve(true)});
  } catch (error) {
      console.error(error);
      throw error;
    }
}

export async function deleteImageRec(params: {
  image: AstroImage
}) {
  const image = params.image;
  try {
    // Delete image from S3 storage
    if (image.source) {
      Storage.remove(image.source);
    }

    // Delete thumbnail from S3 storage
    if (image.thumbnail) {
      Storage.remove(image.thumbnail);
    }

    // Call GraphQL delete operation for db item
    if (image.id) {
      await API.graphql(graphqlOperation(deleteImage, { input: { id: image.id } }));
    }
  } catch (error) {
    console.error("Delete image error", error);
  }
}

export function getThumbWidthHeight(params: { file: File, maxSize: number }): Promise<ImageReaderType> {
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement('canvas');

  const resize = (): Promise<ImageReaderType> => {
    // Determine new dimensions based on params.maxSize
    const originalWidth = image.width;
    const originalHeight = image.height;
    let width = originalWidth;
    let height = originalHeight;
    console.log("Image original width, height :", width, height);
    if (width > height) {
      if (width > params.maxSize) {
        height *= params.maxSize / width;
        width = params.maxSize;
      }
    } else {
      if (height > params.maxSize) {
        width *= params.maxSize / height;
        height = params.maxSize;
      }
    }
    console.log("New thumbnail width, height : ", width, height);

    // Create resized canvas image
    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d")?.drawImage(image, 0, 0, width, height);

    // Convert canvas to File object
    const base64 = canvas.toDataURL("image/png");
    return new Promise<ImageReaderType>((resolve) => {
      base64ToBlob(base64).then((blob) => {
        const source = params.file.name;
        const title = params.file.name.replace(/\.[^/.]+$/, "");
        const thumbFileName = title + ".png";
        const thumbFile = new File([blob], thumbFileName);
        resolve({
          source: source,
          thumbnail: thumbFile,
          width: originalWidth,
          height: originalHeight,
          title: title,
        });
      });
    });
  }

  // Use FileReader to return File in Promise asynchronously
  return new Promise((resolve) => {
    reader.onload = (readerEvent) => {
      image.onload = () => resolve(resize());
      if (readerEvent.target?.result && typeof readerEvent.target.result === "string") {
        image.src = readerEvent.target?.result;
      }
    }
    reader.readAsDataURL(params.file);
  });
}

export async function batchRefactorUrls() {
  getImages({ includeUnReleased: false }).then((images) => {
    images.map((image) => {
      if (image.sourceUrl && image.sourceUrl.includes(".jpeg")) {
        image.sourceUrl = image.sourceUrl.replace(".jpeg", ".jpg");
        updateImage({ image: image });
      }
      return image;
    });

    console.log("New images", images);
  });
}