import React from 'react';
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import './App.css';
import { appTheme } from './theme';
import { AppProvider } from './contexts/app-context';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CssBaseline from "@material-ui/core/CssBaseline";
import { Root } from './routes/root';
import AdminWrapper from './routes/admin';

Amplify.configure(awsconfig);

function App() {

  return (
    <ThemeProvider theme={appTheme}>
      <React.Fragment>
        <CssBaseline />
        <AppProvider>
          <Router>
            <Switch>
              <Route path="/admin">
                <AdminWrapper />
              </Route>
              <Route path="/">
                <Root />
              </Route>
            </Switch>
          </Router>
        </AppProvider>
      </React.Fragment>
    </ThemeProvider>
  );
}

export default App;
