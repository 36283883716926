import {
  Grid,
  createStyles,
  makeStyles,
  Paper,
  SvgIconTypeMap,
  Theme,
  Typography,
  Avatar,
} from "@material-ui/core";
import React from "react";
import AstroHeader from "../components/astro-header";
import { MainSectionProps } from "../types";
import { Telescope, CameraIris, Application, Chip, More } from "mdi-material-ui";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    paper: {
      display: "flex",
      height: "100%",
      padding: theme.spacing(4),
      margin: "auto",
    },
    flex: {
      display: "flex",
    },
    itemContainer: {
      // height: 250,
      alignItems: "center",
    },
    itemContainerLg: {
      minHeight: 250,
      alignItems: "center",
    },
    icon: {
      fontSize: "2.5rem",
    },
    iconCircle: {
      padding: "2rem",
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.main,
    },
  })
);

type EquipListItem = {
  title: string;
  icon: OverridableComponent<SvgIconTypeMap>;
  list: string[];
};

const equipList: EquipListItem[] = [
  {
    title: "Mount",
    icon: Telescope,
    list: ["Celestron AVX German Equatorial Mount"],
  },
  {
    title: "Telescopes",
    icon: Telescope,
    list: ["Sky-Watcher EvoStar 80ED APO Refractor", "Agena StarGuider 50mm (guide scope)", "Celestron 6\" Newtonian"],
  },
  {
    title: "Cameras",
    icon: CameraIris,
    list: [
      "ZWO ASI290MC Pro",
      "ZWO ASI120MM Mini (guide camera)",
      "Canon Rebel T3 DSLR",
      "ZWO ASI120MC-S",
    ],
  },
  {
    title: "Control & Automation",
    icon: Chip,
    list: [
      "Raspberry Pi 4 running Raspberry Pi OS Linux",
      "RealVNC",
      "Ekos & INDI",
      "OpenPHD",
      "FireCapture",
    ],
  },
  {
    title: "Other Accessories",
    icon: More,
    list: [
      "ZWO EAF Electronic Focuser",
      'Optolong L-eNhance 2" Multi-Narrowband filter',
      "Gosky Dew Heater strips",
    ],
  },
  {
    title: "Image Processing",
    icon: Application,
    list: ["PixInsight", "Photoshop & Camera RAW", "AutoStakkert!", "RegiStax"],
  },
];

export const Equipment = React.forwardRef<HTMLDivElement, MainSectionProps>(
  (props: MainSectionProps, ref) => {
    const classes = useStyles();

    const renderEquipmentItem = (item: EquipListItem) => (
      <Grid item xs={12} sm={6}>
        <Paper className={classes.paper}>
          <Grid container spacing={2} className={classes.itemContainer}>
            <Grid item className={classes.flex}>
              <Grid item container direction="column" justify="center">
                <Avatar className={classes.iconCircle}>
                  <item.icon className={classes.icon} />
                </Avatar>
              </Grid>
            </Grid>
            <Grid item xs container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography variant="h2">{item.title}</Typography>
                </Grid>
                {item.list.map((listItem) => (
                  <Grid item>
                    <Typography>{listItem}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );

    return (
      <div style={props.style} ref={ref}>
        <AstroHeader title="Equipment" />
        <Grid container spacing={2} justify="center">
          <Grid item xs={8} sm={6}>
            <Paper className={classes.paper}>
              <img src="/images/20200402_182954.jpg" alt="equipment" className={classes.img} />
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {equipList.map((item) => renderEquipmentItem(item))}
        </Grid>
      </div>
    );
  }
);
